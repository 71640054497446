@import "settings/index";
@import "tools/index";
@import "generic/index";
@import "elements/index";
/*================================================
	@form @inputs @submit
=================================================*/
a {
    text-decoration: none;
}

.form-con {
    padding: $size-6 $size-4 $size-4;
}

.login-form {
    max-width: 300px;
    margin: 0 auto;
}

.login-form.login-oauth {
    max-width: 600px;
}

.login-form.challenge-form {
    max-width: 600px;
}

.login-form h1 {
    font-size: 140%;
    font-weight: 600;
}

.help-block {
    @include font-size($text-sm);
    padding-top: $size-2;
}

.login-form .form-header {
    border-bottom: #9ba7b4 solid 5px;
    padding: 0 0 10px;
}

.form-body {
    display: flex;
    flex-direction: column;
    background: $color-white;

    @include breakpoint(tablet) {
        flex-direction: row;
    }

    .alert {
        color: $color-red-600;
        font-weight: 500;
        padding: $size-0 $size-0 $size-3;
        line-height: 140%;
    }
}

.form-login,
.oauth-con {
    padding: $size-6 $size-4 $size-4;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
}

.oauth-con {
    background: $color-grey-100;
}

.oauth-con h2 {
    margin-bottom: $size-2;
    @include font-size($text-xl);
}

.oauth-con p {
    @include font-size($text-sm);
    line-height: 140%;
}

.login-form p.placeholder {
    padding: 0 0 12px;
}

.login-form p.placeholder:first-child {
    padding: 0;
}

.login-form .placeholder label {
    position: absolute;
    z-index: 10;
    color: #333;
    font-weight: 800;
    top: 10px;
    left: 50px;
    @include font-size($text-sm);
    color: #9aa6b3;
    cursor: text;
}

.forgot-password {
    @include font-size($text-sm);

    text-align: right;
    display: inline-flex;
    padding: $size-3 $size-4;
    background: $color-grey-200;
    border-radius: $size-1;
    margin: $size-2 auto;
    color: $color-primary-500;

    &:hover {
        background: $color-grey-900;
        color: $color-grey-100;
    }
}

form p {
    position: relative;
}

form ul,
form ol {
    list-style: none;
    line-height: 120%;
    padding: 0;
}

/*form label,*/
form select {
    cursor: pointer;
}

form input[type=text],
form input[type=email],
form input[type=password],
form select,
form textarea {
    width: 100%;
    padding: 10px 18px;
    border: #fff solid 0px;
    box-sizing: border-box;
    background: #e9f0ed;
    background-repeat: no-repeat;
    border-radius: 3px;
}

form input.error,
form textarea.error,
form select.error {
    border: #e5394a solid 1px !important;
    background-color: #ffdad6 !important;
}

.form-small-text {
    @include font-size($text-sm);
    padding: 0 0 10px;
}

form label {
    display: block;
    text-transform: uppercase;
    @include font-size($text-xs);
}

form label em {
    color: #999;
    text-transform: none;
}

form label span.red {
    color: red;
}

form .form-checkbox label {
    display: inline-block;
}

form input[type=radio],
form input[type=checkbox],
form input[type=submit],
form #submit {
    cursor: pointer;
    width: auto;
}

form input[type=submit],
form input[type=button]
form #submit {
    -webkit-appearance: none;
    background: #0095d9;
    width: 100%;
    border: #fff solid 0px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    @include font-size($text-sm);
    font-weight: 600;
    padding: 14px 0;
}

form input[type=submit]:hover,
form #submit:hover {
    background-color: #4cb5e4;
}

form input[type=radio] {
    -webkit-appearance: radio;
}

form input[type=checkbox] {
    -webkit-appearance: checkbox;
    margin-top: 0px;
}

form input:focus,
form select:focus,
textarea:focus {
    border-color: #0095da !important;
    background-color: #c9edfe !important;
    outline: none;
}

form input[type=submit]:focus,
form #submit:focus {
    background: #039ade;
}

form input[type=checkbox]:focus {
    outline: dashed;
    outline-color: #0095da;
}

/* Form Tips / Errors / Etc */
form .error input,
form .error select {
    background-color: #ffdad6 !important;
    border-color: #cc7f77 !important;
}

form .placeholder.error label {
    color: #cc7f77 !important;
}

.form-errors {
    @include font-size($text-xs);
    font-weight: 600;
    color: #dd4646;
}

.form-group {
    padding: 30px 30px 20px;
    margin-top: 14px;
    background: #fff;
    border-radius: 3px;
    border: #eee solid 1px;

    .form-group {
        padding: 0 10px 0;
    }
}

.form-group:first-child {
    border-top: none;
}

form.small-form label {
    @include font-size($text-xs);
    font-weight: bold;
    line-height: 120%;
}

label .req {
    color: red;
    font-weight: bold;
}

form.small-form .form-checkbox label {
    font-weight: normal;
}

form.small-form input[type=text],
form.small-form select,
form.small-form textarea,
form button {
    padding: 6px 10px;
    @include font-size($text-sm);
    border: #d6dbe1 solid 1px;
    background-color: #fff;
    border-radius: 3px;
}

form.small-form textarea {
    height: 120px;
}

.form-2,
.form-3,
.form-4,
.form-5,
.form-6 {
    clear: both;
}

.form-2 p,
.form-3 p,
.form-4 p,
.form-5 p,
.form-6 p {
    margin-left: 2.222222222222222%;
    float: left;
}

.form-2 p:first-child,
.form-3 p:first-child,
.form-4 p:first-child,
.form-5 p:first-child,
.form-6 p:first-child {
    margin-left: 0;
}

.form-2 p {
    width: 48.88888888888889%;
}

.form-3 p {
    width: 31.85185185185185%;
}

.form-4 p {
    width: 23.33333333333333%;
}

.form-5 p {
    width: 18.22222222222222%;
}

.submit-form {
    padding: 20px 0 0;

    &:first-child {
        padding-top: 0;
    }
}

.submit-form:first-child {
    padding-top: 0;
}

form.small-form input[type=submit],
form.small-form .submit {
    cursor: pointer;
    width: auto;
}

form.small-form input[type=submit],
form.small-form .submit,
form button {
    -webkit-appearance: none;
    background: #0095d9;
    width: 100%;
    border: #fff solid 0px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    @include font-size($text-sm);
    font-weight: 600;
    padding: 14px 0;
}

form.small-form input[type=submit]:hover,
form.small-form .submit:hover,
form button:hover {
    background-color: #4cb5e4;
}

form.small-form .form-button-small {
}

form.small-form .form-button-small input[type=submit] {
    width: auto;
    text-transform: none;
    @include font-size($text-xs);
    padding: 10px 18px;
    border-radius: 2px;
    float: right;
    margin: 0;
}

/*================================================
	@errors
=================================================*/
.error-con {
    background: #e9f0ed;
    padding: 0 0 20px;
}

.error-con p {
    color: #fff;
    background: #ed808b;
    @include font-size($text-sm);
    padding: 5px 20px;
    border-radius: 3px;
    border: #e5394a solid 1px;
}


.btn-login {
    display: block;
    margin-bottom: 20px;
  
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eaeaea+0,dbdbdb+100 */
    background: rgb(234,234,234); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(234,234,234,1) 0%, rgba(219,219,219,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(234,234,234,1) 0%,rgba(219,219,219,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(234,234,234,1) 0%,rgba(219,219,219,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#dbdbdb',GradientType=0 ); /* IE6-9 */
  
    color: #654444;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    height: 42px;
    overflow: hidden;
    position: relative;
    @include font-size($text-sm);
  }
  .btn-login i {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ea4f5c+0,ed1b2e+100 */
    background: rgb(234,79,92); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(234,79,92,1) 0%, rgba(237,27,46,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(234,79,92,1) 0%,rgba(237,27,46,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(234,79,92,1) 0%,rgba(237,27,46,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea4f5c', endColorstr='#ed1b2e',GradientType=0 ); /* IE6-9 */
  
    height: 42px;
    line-height: 40px;
  
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
  
    border-bottom: #bb0909 solid 2px;
    border-left: #ED1B2E solid 1px;
    border-top: #ED1B2E solid 1px;
    /*border-top: #ea4f5c solid 1px;*/
  
    display: block;
    float: left;
    padding:0 10px;
    width: 64px;
    margin-right: 16px;
    overflow: hidden;
  
    transition:all 0.15s;
    -moz-transition:all 0.15s;
    -webkit-transition:all 0.15s;
    -o-transition:all 0.15s;
}
.btn-login i img {
max-width: 44px;
}
.btn-login i span {
color: #fff;
/*margin-left: 27px;*/
position: absolute;
left: 64px;
top: 0;
bottom: 0;
display: block;
border-left: #bb0909 solid 1px;
padding-left: 14px;

}
.btn-login > span {
display: block;
width: 100%;
height: 42px;
line-height: 41px;
padding-left: 80px;
border: #ccc solid 1px;
border-bottom-color: #b8b8b8;
border-bottom-width: 2px;
position: relative;
font-weight: 500;
z-index: 1;
}

.btn-login:hover i {
width: 100%;
}
.btn-login:active i {
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ed1b2e+0,ea4f5c+100 */
background: rgb(237,27,46); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(237,27,46,1) 0%, rgba(234,79,92,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(237,27,46,1) 0%,rgba(234,79,92,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(237,27,46,1) 0%,rgba(234,79,92,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed1b2e', endColorstr='#ea4f5c',GradientType=0 ); /* IE6-9 */

border-bottom-width: 1px;
}

.btn-login:active i img {
position: relative;
top: 1px;
}
.btn-login:active i span {
line-height: 42px;
}
