* {
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -o-box-sizing:border-box;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr,
acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong,
sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;outline:none;
}
img {border:0;-ms-interpolation-mode:bicubic;vertical-align:middle;max-width:100%;}
ol, ul {list-style:none;}
blockquote, q {quotes:none;}
blockquote:before, blockquote:after, q:before, q:after {content:"";content:none;}
small {font-size:80%;}
ins {background:#ff0;color:#000;text-decoration:none;}
mark {background:#ff0;color:#000;}
del {text-decoration:line-through;}
b, strong {font-weight:bold;}
em, dfn {font-style:italic;}
abbr[title], dfn[title] {border-bottom:1px dotted;cursor:help;}
table {border-collapse:collapse;border-spacing:0;}
hr {height:0;box-sizing:content-box;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;}
legend {white-space:normal;}
table {border-collapse:collapse;border-spacing:0;}
caption, th, td {text-align:left;font-weight:normal;vertical-align:middle;}
pre, code, kbd, samp {font-family:monospace, serif;_font-family:'courier new', monospace;font-size:1em;}
pre {white-space:pre;white-space:pre-wrap;word-wrap:break-word;} /* Improve readability of pre-formatted text in all browsers */
svg:not(:root) {overflow:visible;} /* Correct overflow not hidden in IE9 */
sub, sup {font-size:75%;line-height:0;position:relative;vertical-align:baseline;}
sup {top:-0.5em;}
sub {bottom:-0.25em;}

/* HTML5 Definitions */
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {display:block;}
audio, canvas, video {display:inline-block;display:inline;zoom:1;}
audio:not([controls]) {display:none;height:0;}
[hidden] {display:none;}

/* Reset Form Styles */
button[disabled], input[disabled] {cursor:default;}
button, input {line-height:normal;} /* Define line-height as normal to match FF3/4 */
select, input, textarea, button {font-size:100%;vertical-align:baseline;vertical-align:middle;text-transform:none;} /* Font Normalization */
textarea {overflow:auto;vertical-align:top;resize:vertical;}
button {width:auto;overflow:visible;border:$color-white solid 0px;} /* Make buttons play nice in IE */
button::-moz-focus-inner, input::-moz-focus-inner {border:0;padding:0;} /* Remove inner padding and border in FF3/4 */

/* Consistent box sizing and appearance */
input[type="checkbox"], input[type="radio"] {padding:0;width:13px;height:13px;}
input[type="search"] {-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {-webkit-appearance:none;}
button::-moz-focus-inner, input::-moz-focus-inner {border:0;padding:0;} /* Remove inner padding and border in FF3/4: h5bp.com/l */

/* Align checkboxes, radios, text inputs with their label */
input[type="checkbox"],
input[type="radio"] {height:13px;width:13px;vertical-align:middle;}

/* Hand cursor on clickable input elements */
label, input[type="button"], input[type="submit"], input[type="reset"],
input[type="image"], button {cursor:pointer;-webkit-appearance:none;overflow:visible;}

/* Colors for form validity */
input:valid, textarea:valid {}
input:invalid, textarea:invalid {background-color:#f0dddd;}