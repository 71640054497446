// TODO : update these Old timeline color variables...
$border__base-color: #D3D3D3;
$primary-color: #0095da;
$danger-color: #cf2828;
$icon-color: #666;
$success-color: #8AC45C;


// 100-900 = 4:base + 1-3,5-9:shades
$color-white: #fff;

// grey
$color-grey-100: hsla(205, 25%, 96%, 1);
$color-grey-200: hsla(210, 25%, 92%, 1);
$color-grey-300: hsla(211, 24%, 88%, 1);
$color-grey-400: hsla(211, 22%, 82%, 1);
$color-grey-500: hsla(210, 18%, 65%, 1);
$color-grey-600: hsla(208, 15%, 54%, 1);
$color-grey-700: hsla(209, 16%, 35%, 1);
$color-grey-800: hsla(210, 16%, 26%, 1);
$color-grey-900: hsla(210, 18%, 18%, 1);

// yellow
$color-yellow-100: hsla(60, 100%, 97%, 1);
$color-yellow-200: hsla(58, 97%, 87%, 1);
$color-yellow-300: hsla(55, 91%, 76%, 1);
$color-yellow-400: hsla(51, 89%, 67%, 1);
$color-yellow-500: hsla(47, 81%, 61%, 1);
$color-yellow-600: hsla(40, 67%, 51%, 1);
$color-yellow-700: hsla(36, 71%, 42%, 1);
$color-yellow-800: hsla(32, 75%, 34%, 1);
$color-yellow-900: hsla(30, 74%, 26%, 1);

// red
$color-red-100: hsla(359, 89%, 96%, 1);
$color-red-200: hsla(359, 72%, 89%, 1);
$color-red-300: hsla(359, 70%, 73%, 1);
$color-red-400: hsla(359, 61%, 64%, 1);
$color-red-500: hsla(359, 63%, 59%, 1);
$color-red-600: hsla(359, 50%, 50%, 1);
$color-red-700: hsla(359, 54%, 39%, 1);
$color-red-800: hsla(359, 58%, 31%, 1);
$color-red-900: hsla(359, 56%, 25%, 1);

// blue
$color-blue-100: hsla(225, 89%, 96%, 1);
$color-blue-200: hsla(224, 72%, 89%, 1);
$color-blue-300: hsla(223, 70%, 73%, 1);
$color-blue-400: hsla(227, 61%, 64%, 1);
$color-blue-500: hsla(231, 63%, 59%, 1);
$color-blue-600: hsla(231, 50%, 50%, 1);
$color-blue-700: hsla(231, 54%, 39%, 1);
$color-blue-800: hsla(231, 58%, 31%, 1);
$color-blue-900: hsla(231, 56%, 25%, 1);

// green
$color-green-100: hsla(136, 100%, 97%, 1);
$color-green-200: hsla(139, 73%, 87%, 1);
$color-green-300: hsla(141, 61%, 75%, 1);
$color-green-400: hsla(143, 55%, 62%, 1);
$color-green-500: hsla(145, 46%, 51%, 1);
$color-green-600: hsla(148, 48%, 43%, 1);
$color-green-700: hsla(150, 48%, 35%, 1);
$color-green-800: hsla(152, 45%, 28%, 1);
$color-green-900: hsla(152, 42%, 23%, 1);









// Primary
$color-primary-100: $color-blue-100;
$color-primary-200: $color-blue-200;
$color-primary-300: $color-blue-300;
$color-primary-400: $color-blue-400;
$color-primary-500: $color-blue-500;
$color-primary-600: $color-blue-600;
$color-primary-700: $color-blue-700;
$color-primary-800: $color-blue-800;
$color-primary-900: $color-blue-900;





/*
 *  Base Colors
 */

$primary-color:         $color-primary-400;
$secondary-color:       $color-red-400;

$featured-color:       $color-yellow-500;


/*
 *  Background Colors
 */

$html__bgcolor:         $color-grey-400;
$body__bgcolor:         transparent;




