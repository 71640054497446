
/*
 *  Breakpoints for Responsive Design
 *  TODO : - have this feed into the mixins file for defining breakpoints
 *  TODO : - use REM sizing
 */

    $phone: 320px;
    $phone-landscape: 480px;
    $tablet: 760px;
    $tablet-landscape: 1020px;
    $desktop: 1260px;
    $desktop-hd: 1400px;
