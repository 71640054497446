

/*
 *  Animations
 *
 *  Used for a variety of transitions.
 */

    // $animate__cubic-bezier: cubic-bezier(.82,.01,.32,1);
    // $animate__cubic-bezier--enter: cubic-bezier(.71,.52,.22,1.04);
    // $animate__cubic-bezier--leave: cubic-bezier(.43,.94,.53,.99);



/*
 *  Fonts
 *
 *  ...
 */

    // $font__base-color: #333;
    // $font__base-family: 'Open Sans', Helvetica, Arial, Verdana, sans-serif;

    $font__fontawesome: 'Font Awesome 5 Free';



/*
 *  Pullouts
 *
 *  Used for timeline calendar, breakdown, and filters.
 */

    $pullout__border-color: $border__base-color;
    $pullout__bg: #fff;
    $pullout__width: 320px;



/*
 *  Calendar
 *
 *  Used for calendar pullout.
 */

    $day__bg: #EAEEEF;
    $day__bg--placeholder: #FAFBFB;



/*
 *  NPProgress
 *
 *  Used for the NPProgress Bar.
 */

    // $pbar__bar-color: $primary-color;
    // $pbar__blur-color: $primary-color;
    // $pbar__spin-color: $primary-color;



/*
 *  Toolbar / ProfileSelector
 *
 *  Used for event toolbar and the profile selector.
 */

    $toolbar_bg: #556062;
    $toolbar_border: #516164;




/*
 *  Colors
 *
 *  Used to loop through when needed for statuses
 */

    $bg__green: #73bc26;
    $color__green: #fff;

    $bg__orange: #d89452;
    $color__orange: #fff;

    $bg__red: #e5394a;
    $color__red: #fff;

    $bg__blue: $primary-color;
    $color__blue: #fff;

    $bg__grey: #677173;
    $color__grey: #fff;

    $lighten: 30%;
    $timelineColors:
        ('green', '#{$color__green}', '#{$bg__green}', lighten($bg__green, $lighten))
        ('blue', '#{$color__blue}', '#{$bg__blue}', lighten($bg__blue, $lighten))
        ('orange', '#{$color__orange}', '#{$bg__orange}', lighten($bg__orange, $lighten))
        ('red', '#{$color__red}', '#{$bg__red}', lighten($bg__red, $lighten))
        ('grey', '#{$color__grey}', '#{$bg__grey}', lighten($bg__grey, $lighten));




/*
 *  Toolbar / ProfileSelector
 *
 *  Used for event toolbar and the profile selector.
 */

    $VisualTimeline__popup_width: 320px;
    $VisualTimeline__popup_min_height: 300px;
