@font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/ProximaNova-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: italic;
    src: url('/fonts/ProximaNova-LightItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/ProximaNova-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: italic;
    src: url('/fonts/ProximaNova-RegularItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-style: normal;
    src: url('/fonts/ProximaNova-Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-style: italic;
    src: url('/fonts/ProximaNova-SemiboldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: normal;
    src: url('/fonts/ProximaNova-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: italic;
    src: url('/fonts/ProximaNova-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/ProximaNova-Extrabold.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 800;
    font-style: normal;
    src: url('/fonts/ProximaNova-Black.otf') format('opentype');
}


$font__base-color: #444;
$font__base-family: 'Proxima Nova', -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
$font__base-style: normal;
$font__base-weight: 400;

$font__base-anchor-color: $primary-color;
$font__base-anchor-hover-color: $secondary-color;

$font__title-family: 'Proxima Nova', -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
$font__title-weight: 200;
$font__title-letterspacing: -0.10rem;



// Font Weights
$font-light:        300;
$font-normal:       400;
$font-semibold:     500;
$font-bold:         600;
$font-extrabold:    700;
$font-black:        800;

// TODO : search all instance of `@include font-size($size` and replace with $text size variables
// Font Sizes
$text-xxs:	        0.625rem;    // 10
$text-xs:	        0.75rem;    // 12
$text-sm:	        0.875rem;   // 14
$text-base:	        1rem;       // 16
$text-lg:	        1.0625rem;  // 17
$text-xl:	        1.125rem;   // 18
$text-2xl:	        1.25rem;    // 20
$text-3xl:	        1.4375rem;  // 23
$text-4xl:	        1.625rem;   // 26
$text-5xl:	        2rem;       // 32
$text-6xl:	        2.5rem;     // 40


// Custom Font Variables
$fa:                '.svg-inline--fa';  // Font Awesome SVG Selector
