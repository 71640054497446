
/*
 *  Base Animation Variables
 */

    // cubic bezier values
    $bounce-v1: 0.175, 0.885, 0.32, 1.275;
    $bounce-v2: .71,.52,.22,1.04;

    // cubic bezier
    $animate__cubic-bezier: cubic-bezier(.82,.01,.32,1);
    $animate__cubic-bezier--enter: cubic-bezier(.71,.52,.22,1.04);
    $animate__cubic-bezier--leave: cubic-bezier(.83,-0.34,.19,1.23);
